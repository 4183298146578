.icon-alt
  width: 24px
  height: 24px
  background-repeat: no-repeat
  background-position: center
  background-size: contain

  &_big
    width: 32px
    height: 32px

  &_brush
    background-image: url('#{$path}/img/icons-alt/brush.svg')

  &_check-square-offset
    background-image: url('#{$path}/img/icons-alt/check-square-offset.svg')

  &_download-simple
    background-image: url('#{$path}/img/icons-alt/download-simple.svg')

  &_files
    background-image: url('#{$path}/img/icons-alt/files.svg')

  &_notification
    background-image: url('#{$path}/img/icons-alt/notification.svg')

  &_pencil-simple
    background-image: url('#{$path}/img/icons-alt/pencil-simple.svg')

  &_push-pin-simple-slash
    background-image: url('#{$path}/img/icons-alt/push-pin-simple-slash.svg')

  &_push-pin-simple
    background-image: url('#{$path}/img/icons-alt/push-pin-simple.svg')

  &_question
    background-image: url('#{$path}/img/icons-alt/question.svg')

  &_reply
    background-image: url('#{$path}/img/icons-alt/reply.svg')

  &_scale-x
    transform: scaleX(-1)

  &_trash-simple
    background-image: url('#{$path}/img/icons-alt/trash-simple.svg')
