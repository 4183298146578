.context-menu
  position: absolute
  top: 0
  left: 0
  z-index: 2
  pointer-events: none

  &__wrapper
    position: absolute
    visibility: hidden
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 8px
    opacity: 0
    transition: visibility $tr-options, opacity $tr-options
    z-index: 2
    pointer-events: initial

    &_active
      visibility: visible
      opacity: 1

  &__list
    padding: 12px 0
    background: $gray-100
    border-radius: 12px
    box-shadow: $box-shadow

    #{$dark-theme}
      background: rgb(83, 84, 97, .98)

  &__item
    display: flex
    align-items: center
    gap: 6px
    padding: 4px 8px
    transition: background $tr-options
    cursor: pointer
    user-select: none

    .icon
      #{$any-theme}
        fill: $gray-500

    .text
      @include nowrap

    &:hover
      @media (hover)
        background: #eef1f4

        #{$dark-theme}
          background: $gray-770
