*
  margin: 0
  padding: 0
  background: none
  border: none
  outline: none
  box-sizing: border-box
  font-family: -apple-system, BlinkMacSystemFont, sans-serif
a
  text-decoration: none

body
  background-color: $gray-150
  color: $text
  overflow-x: hidden
  user-select: none

  &.dark-theme
    color: $white

  &.dark-theme
    background: #1f2b39

  @media (hover)
    user-select: initial

em
  font-style: intalic

form
  display: contents

strong
  font-weight: 600

.line-through
  text-decoration: line-through

.monospace
  font-family: monospace

#root
  height: 100svh
